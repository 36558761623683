.player-badges {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  z-index: 10;
  padding: 0 22px;
  width: 100%;
  justify-content: center;

  > .player-badge {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #f0f0f0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    border: 1px solid #fff;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}