.poker-card {
  position: relative;
  background: white;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
  font-family: 'Arial', sans-serif;
  border: 1px solid #ddd;
  width: 80px;
  height: 120px;
  cursor: pointer;
  &.heart, &.diamond { color: #e44145; }
  &.club, &.spade { color: #252525; }
  &.hidden-card {
    background-color: #aaaaaa;
  }
  .card-corner {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 0.8;
    &.card-corner-top {
      top: 5px;
      left: 5px;
    }
    &.card-corner-bottom {
      bottom: 5px;
      right: 5px;
      transform: rotate(180deg);
    }
    .card-value {
      font-size: 1.5em;
      font-weight: bold;
    }
    .corner-symbol { font-size: 2em; }
  }
  .center-symbol {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5em;
  }
}