/* Styles pour la collection de badges */
.badge-collection {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.badge-item {
  display: inline-flex;
  align-items: center;
  border-radius: 50px;
  overflow: hidden;
}

.badge-image {
  object-fit: cover;
  border-radius: 4px;
}

/* Style pour le formulaire de badges */
.field-badges .form-widget .choices__inner {
  padding: 4px;
  min-height: 44px;
}

.field-badges .form-widget .choices__input {
  min-width: 150px;
}

/* Style pour le bouton d'ajout de badge */
.new-badge-btn {
  margin-top: 8px;
  font-size: 14px;
}