.player-avatar {
  width: 100%;
  height: 100%;
  margin: 0 20px;
  position: relative;

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
}