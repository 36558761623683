.player-hand {
  display: flex;
  position: relative;
  width: 110px;
  height: 60px;
  overflow: hidden;
  .hand-folded {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .mdi.mdi-account {
      font-size: 85px;
      color: #000000;
    }
    .mdi.mdi-cards-playing-diamond-multiple {
      position: absolute;
      font-size: 24px;
      top: 0;
      left: 0;
      transform: rotate(326deg);
      color: #000000;
    }
  }
}