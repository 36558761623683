.question-content {

  .question-details {
    background-color: #c9cfff;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    p {
      margin-bottom: 0;
    }
  }

  .hands-section,
  .players-section,
  .table-situations-section,
  .replayers-section,
  .range-groups-section {
    margin: 1.5rem 0;
    padding: 1rem;
  }

  .hands-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .players-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    padding: 40px 0;
  }

  .table-situations-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
  }

  .hands-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .range-groups-section {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
  }

  .answers-section {
    margin-top: 2rem;

    .answer-options {
      background-color: #ffffff;
      padding: 30px;
      border-radius: 20px;
      border: 7px double #dbdbdb;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
      &.answer-response-error {
        border-color: red;
      }
      &.answer-response-warning {
        border-color: orange;
      }
      &.answer-response-success {
        border-color: green;
      }
      p {
        margin-bottom: 0;
      }
      .answer-option {
        margin: 1rem 0;
        position: relative;

        .form-check {
          display: flex;
          align-items: center;

          label {
            display: flex;
            margin-left: 0.5rem;
            cursor: pointer;

            .player {
              .profile {
                color: black;
              }
            }

            .poker-card {
              transform: scale(0.7);
            }
          }

          input {
            cursor: pointer;

            &:disabled + label {
              cursor: default;
              opacity: 0.7;
            }
          }

          .correct {
            color: #28a745;
          }

          .incorrect {
            color: #dc3545;
          }

          .answer-details {
            margin: 0.5rem 0 0.5rem 2rem;
            padding: 1rem;
            border-left: 3px solid #e9ecef;
            background-color: #f8f9fa;
            border-radius: 0 0.25rem 0.25rem 0;

            .details-content {
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
    .question-more-details {
      margin-top: 20px;
      padding: 30px;
      background-color: #cacefe;
      border-radius: 20px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    }
  }

  .control-buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}