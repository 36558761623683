.poker-hand {
  display: flex;
  position: relative;
  width: 110px;
  height: 60px;
  overflow: hidden;
  .poker-card {
    transition: transform 0.3s ease;
    position: absolute;
    top: 0;
    &:hover {
      /*transform: translateY(-10px) scale(1.05);*/
      /*box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);*/
      z-index: 1;
    }
    &:nth-of-type(2) {
      left: 30px;
    }
  }
}