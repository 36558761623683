.range-selector {
  --cell-size: 2rem;
  display: flex;

  &__grid-container {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 0.5rem;
    padding: 1rem;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(13, var(--cell-size));
    gap: var(--cell-gap);
    height: fit-content;
  }

  &__cell {
    position: relative;
    aspect-ratio: 1;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      z-index: 1;
    }

    &.selected {
      border-color: #fff;
    }

    &__suited {
      background: #fffbdf;
    }

    &__offsuited {
      background: #ffe0e0;
    }

    &__pair {
      background: #dee9fb;
    }
  }

  &__cell-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__hand-text {
    position: relative;
    z-index: 2;
    font-size: 0.875rem;
    font-weight: 600;
    color: #000000;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
    user-select: none;
    -webkit-user-select: none; /* Pour Safari */
    -moz-user-select: none;
  }

  &__controls {
    padding: 1rem;
    background: #f5f5f5;
    border-radius: 0.5rem;
    width: 300px;
  }

  &__export {
    width: 100%;
    padding: 0.5rem;
    background: #2563eb;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: #1d4ed8;
    }
  }

  &__colors {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .color-item {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 5px;
    border-radius: 0.25rem;
    color: black;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    width: 100%;
    justify-content: space-between;

    &__name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }

    button {
      background: none;
      border: none;
      color: inherit;
      cursor: pointer;
      font-size: 1.2rem;
      padding: 0 0.25rem;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    span {
      cursor: pointer;
      padding: 0.1rem 0.25rem;
      border-radius: 0.2rem;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    &__actions {
      display: flex;
      gap: 0.25rem;
    }

    &__edit-input {
      background: rgba(255, 255, 255, 0.9);
      border: none;
      border-radius: 0.2rem;
      padding: 0.1rem 0.25rem;
      color: #000;
      font-size: 0.9rem;
      width: auto;
      min-width: 4rem;
    }

    button {
      background: none;
      border: none;
      color: inherit;
      cursor: pointer;
      font-size: 1rem;
      padding: 0.1rem 0.25rem;
      border-radius: 0.2rem;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    transition: all 0.2s ease;
    cursor: pointer;
    border: 2px solid transparent;

    &__active {
      border-color: white;
      box-shadow: 0 0 0 2px #000;
    }

    user-select: none;
  }

  &__color-form {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;

    input[type="text"] {
      flex: 1;
      padding: 0.25rem 0.5rem;
      border: 1px solid #ddd;
      border-radius: 0.25rem;
    }

    input[type="color"] {
      width: 3rem;
      padding: 0;
      border: 1px solid #ddd;
      border-radius: 0.25rem;
    }

    button {
      padding: 0.25rem 0.5rem;
      background: #2563eb;
      color: white;
      border: none;
      border-radius: 0.25rem;
      cursor: pointer;

      &:hover {
        background: #1d4ed8;
      }
    }
  }

  &__color-selector {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    select {
      flex: 1;
      padding: 0.25rem 0.5rem;
      border: 1px solid #ddd;
      border-radius: 0.25rem;
    }
  }

  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
    border-radius: 3px;

    &-segment {
      position: absolute;
      height: 100%;
      top: 0;
      transition: all 0.2s ease;
      opacity: 0.8;
    }
  }

  &__clear {
    width: 100%;
    padding: 0.5rem;
    background: #ef4444;  // Rouge
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: #dc2626;
    }
  }

  &__import {
    width: 100%;
    padding: 0.5rem;
    background: #2563eb;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: #1d4ed8;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__frequency-select {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  &__slider {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;

    input[type="range"] {
      flex: 1;
    }
  }

  &__force-fill {
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
  }

  &__frequency-buttons {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  &__freq-button {
    flex: 1;
    padding: 0.25rem 0;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #e0e0e0;
    }

    &:active {
      background-color: #d0d0d0;
    }
  }

  &__stats-total {
    font-size: 1.1rem;
    margin-bottom: 0.8rem;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: space-between;

    >span {
      font-weight: bold;
    }
  }

  &__stats-colors {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__color-stat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &__color-stat-label {
    display: flex;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-radius: 0.25rem;
    font-weight: 500;
  }

  &__color-stat-value {
    font-weight: 500;
  }

  &__section-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #333;
  }

  &__locked {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.1);
      pointer-events: none;
      border-radius: 0.25rem;
    }
  }

  &__strength-selector {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eaeaea;
  }

  &__dual-slider {
    margin-bottom: 1rem;

    &-container {
      position: relative;
      height: 1px;
    }

    &-min, &-max {
      position: absolute;
      width: 100%;
      height: 5px;
      background: none;
      pointer-events: none;
      appearance: none;
      -webkit-appearance: none;

      &::-webkit-slider-thumb {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #2563eb;
        cursor: pointer;
        pointer-events: auto;
        -webkit-appearance: none;
        appearance: none;
      }

      &::-moz-range-thumb {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background: #2563eb;
        cursor: pointer;
        pointer-events: auto;
        -moz-appearance: none;
        appearance: none;
      }
    }

    &-min {
      z-index: 2;
    }

    &-max {
      z-index: 1;
    }

    &-values {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      font-size: 0.9rem;
    }
  }

  &__strength-apply {
    width: 100%;
    padding: 0.5rem;
    background: #2563eb;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: #1d4ed8;
    }
  }

  /* Pseudo-élément pour créer la ligne entre les deux curseurs */
  &__dual-slider-container::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 2px;
    transform: translateY(-50%);
    height: 6px;
    background: #1d4ed8;
    border-radius: 5px;
  }

  &__save {
    width: 100%;
    padding: 0.5rem;
    background: #10b981;  // Vert
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: #059669;
    }
  }

  &__name-field {
    margin-top: 1rem;

    input {
      width: 100%;
      padding: 0.5rem;
      border: 1px solid #ddd;
      border-radius: 0.25rem;
      font-size: 0.9rem;

      &:focus {
        outline: none;
        border-color: #2563eb;
        box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.2);
      }
    }
  }

  &__load {
    width: 100%;
    padding: 0.5rem;
    background: #6366f1;  // Indigo
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s ease;

    &:hover {
      background: #4f46e5;
    }
  }

  &__action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 1rem;
  }

  &__action-button {
    flex: 1;
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    i {
      font-size: 1rem;
    }

    &:nth-child(1) {
      background: #ef4444; /* Rouge - Supprimer */
      &:hover {
        background: #dc2626;
      }
    }

    &:nth-child(2) {
      background: #2563eb; /* Bleu - Exporter */
      &:hover {
        background: #1d4ed8;
      }
    }

    &:nth-child(3) {
      background: #2563eb; /* Bleu - Importer */
      &:hover {
        background: #1d4ed8;
      }
    }

    &:nth-child(4) {
      background: #10b981; /* Vert - Sauvegarder */
      &:hover {
        background: #059669;
      }
    }

    &:nth-child(5) {
      background: #6366f1; /* Indigo - Charger */
      &:hover {
        background: #4f46e5;
      }
    }
  }
}