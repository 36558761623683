/** Poker Table */
.poker-table {
  background: #6ad08e;
  border-radius: 200px;
  position: relative;
  width: 800px;
  height: 400px;
  margin: 60px;
  border: 30px solid #008323;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  .seat {
    position: absolute;
    .dealer-token {
      position: absolute;
      top: -10px;
      left: -10px;
      width: 32px;
      height: 32px;
      background-color: white;
      border-radius: 15px;
      border: 5px double black;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      font-weight: bold;
      letter-spacing: -1px;
      z-index: 10;
    }
  }
  &.max-players-2 {
    width: 400px;
    height: 200px;
    .seat {
      &.seat-1 { left: 0%; top: 50%; transform: translate(-50%, -65px); }
      &.seat-2 { right: 0%; top: 50%; transform: translate(50%, -65px); }
    }
  }
  &.max-players-3 {
    width: 400px;
    height: 280px;
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%,-50px); }
      &.seat-2 { left: 0%; top: 15%; transform: translate(-50%, -65px); }
      &.seat-3 { right: 0%; top: 15%; transform: translate(50%, -65px); }
    }
  }
  &.max-players-4 {
    width: 400px;
    height: 400px;
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%, -65px); }
      &.seat-2 { left: 0%; top: 10%; transform: translate(-50%, 65px); }
      &.seat-3 { left: 50%; top: 0%; transform: translate(-50%, -65px); }
      &.seat-4 { right: 0%; top: 10%; transform: translate(50%, 65px); }
    }
  }
  &.max-players-5 {
    width: 600px;
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%, -65px); }
      &.seat-2 { left: 0%; top: 70%; transform: translate(-50%, -65px); }
      &.seat-3 { left: 15%; top: 0%; transform: translate(-50%, -65px); }
      &.seat-4 { right: 15%; top: 0%; transform: translate(50%, -65px); }
      &.seat-5 { right: 0%; top: 70%; transform: translate(50%, -65px); }
    }
  }
  &.max-players-6 {
    width: 600px;
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%,-65px); }
      &.seat-2 { left: 10%; top: 85%; transform: translate(-50%, -65px); }
      &.seat-3 { left: 10%; top: 25%; transform: translate(-50%, -65px); }
      &.seat-4 { left: 50%; top: 0%; transform: translate(-50%, -65px); }
      &.seat-5 { right: 10%; top: 25%; transform: translate(50%, -65px); }
      &.seat-6 { right: 10%; top: 85%; transform: translate(50%, -65px); }
    }
  }
  &.max-players-7 {
    width: 600px;
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%,-65px); }
      &.seat-2 { left: 10%; top: 85%; transform: translate(-50%, -65px); }
      &.seat-3 { left: 0%; top: 40%; transform: translate(-50%, -65px); }
      &.seat-4 { left: 30%; top: 0%; transform: translate(-50%, -65px); }
      &.seat-5 { right: 30%; top: 0%; transform: translate(50%, -65px); }
      &.seat-6 { right: 0%; top: 40%; transform: translate(50%, -65px); }
      &.seat-7 { right: 10%; top: 85%; transform: translate(50%, -65px); }
    }
  }
  &.max-players-8 {
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%,-65px); }
      &.seat-2 { left: 20%; top: 95%; transform: translate(-50%, -65px); }
      &.seat-3 { left: 0%; top: 50%; transform: translate(-50%, -65px); }
      &.seat-4 { left: 20%; top: 5%; transform: translate(-50%, -65px); }
      &.seat-5 { left: 50%; top: 0%; transform: translate(-50%, -65px); }
      &.seat-6 { right: 20%; top: 5%; transform: translate(50%, -65px); }
      &.seat-7 { right: 0%; top: 50%; transform: translate(50%, -65px); }
      &.seat-8 { right: 20%; top: 95%; transform: translate(50%, -65px); }
    }
  }
  &.max-players-9 {
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%,-65px); }
      &.seat-2 { left: 20%; top: 95%; transform: translate(-50%, -65px); }
      &.seat-3 { left: 0%; top: 50%; transform: translate(-50%, -65px); }
      &.seat-4 { left: 15%; top: 5%; transform: translate(-50%, -65px); }
      &.seat-5 { left: 39%; top: 0%; transform: translate(-50%, -65px); }
      &.seat-6 { right: 39%; top: 0%; transform: translate(50%, -65px); }
      &.seat-7 { right: 15%; top: 5%; transform: translate(50%, -65px); }
      &.seat-8 { right: 0%; top: 50%; transform: translate(50%, -65px); }
      &.seat-9 { right: 20%; top: 95%; transform: translate(50%, -65px); }
    }
  }
  &.max-players-10 {
    .seat {
      &.seat-1 { left: 50%; top: 100%; transform: translate(-50%,-65px); }
      &.seat-2 { left: 20%; top: 95%; transform: translate(-50%, -65px); }
      &.seat-3 { left: 0%; top: 75%; transform: translate(-50%, -65px); }
      &.seat-4 { left: 0%; top: 30%; transform: translate(-50%, -65px); }
      &.seat-5 { left: 20%; top: 5%; transform: translate(-50%, -65px); }
      &.seat-6 { left: 50%; top: 0%; transform: translate(-50%, -65px); }
      &.seat-7 { right: 20%; top: 5%; transform: translate(50%, -65px); }
      &.seat-8 { right: 0%; top: 30%; transform: translate(50%, -65px); }
      &.seat-9 { right: 0%; top: 75%; transform: translate(50%, -65px); }
      &.seat-10 { right: 20%; top: 95%; transform: translate(50%, -65px); }
    }
  }
  .table-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    .board {
      display: flex;
      flex-direction: row;
    }
    .pot-info {
      font-size: 1.5em;
      margin-bottom: 10px;
    }
    .game-info {
      font-size: 1.2em;
      display: flex;
      gap: 15px;
      justify-content: center;
    }
    .round-info {
      margin-top: 10px;
      font-size: 1.1em;
      color: #560000;
    }
    .pot-info,
    .game-info,
    .round-info {
      .label {
        color: #faff30;
        margin-right: 5px;
      }
      .value {
        color: #ffffff;
        font-weight: bold;
      }
    }
  }
  .seat {
    position: absolute;
    transform: translate(-50%, 50%);
  }
}