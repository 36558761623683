.replayer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .replayer-situations {
    background-color: #ccc;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    border-radius: 300px;
    .situation {
      transition: opacity 0.3s ease;
    }
  }

  .replayer-controls {
    display: flex;
    justify-content: center;
    position: relative;
    gap: 12px;
    padding: 10px;
    border-radius: 30px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.05);
    background-color: #000000;

    .replayer-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: none;
      border-radius: 50%;
      background-color: #fff;
      color: #333;
      cursor: pointer;
      transition: all 0.2s ease;
    }

    .replayer-button:hover {
      background-color: #f0f0f0;
      transform: scale(1.05);
    }

    .replayer-button:active {
      transform: scale(0.95);
    }

    .replayer-button i {
      font-size: 24px;
    }

    .replayer-position {
      position: absolute;
      top: -20px;
      font-weight: bold;
    }
  }
}



