.range-viewer-mini {
  --cell-size: 2.5px;
  --cell-gap: 0.5px;
  width: fit-content;
  height: fit-content;
  position: relative;
  cursor: pointer;

  &__container {
    background: #f5f5f5;
    border-radius: 2px;
    padding: 1px;
    max-width: fit-content;
    max-height: 40px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(13, var(--cell-size));
    gap: var(--cell-gap);
    height: fit-content;
  }

  &__cell {
    position: relative;
    aspect-ratio: 1;
    width: var(--cell-size);
    height: var(--cell-size);

    &__suited {
      background: #fffbdf;
    }

    &__offsuited {
      background: #ffe0e0;
    }

    &__pair {
      background: #dee9fb;
    }

    &.selected {
      position: relative;
      overflow: hidden;

      .range-viewer-mini__fill {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__tooltip {
    display: none;
    position: absolute;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% + 5px);
    background: white;
    border-radius: 5px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
    padding: 15px;

    /* Ajout d'un espace invisible entre le mini-viewer et le tooltip pour éviter le "saut" */
    &::before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      width: 100%;
      height: 10px;
      background: transparent;
    }

    // En mode tooltip affiché
    &.active {
      display: block;
    }

    /* Nouvelle règle : le tooltip reste visible au survol */
    &:hover {
      display: block;
    }
  }
}