.player {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  color: white;
  width: 140px;
  &.player--active {
    .poker-hand {
      box-shadow: 0 0 20px 5px #fff347;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .player-stack-bet {
    font-size: 1em;
    color: yellow;
    padding: 2px 8px;
    border-radius: 4px;
    z-index: 10;
    font-weight: bold;
    position: absolute;
    top: -30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    .chips {
      width: 20px;
      height: 20px;
      display: block;
      background: green;
      border: 5px solid white;
      border-style: dotted;
      border-radius: 10px;
      margin-bottom: 2px;
    }
    .bet-amount {
      background-color: black;
      border-radius: 5px;
      padding: 0px 5px;
    }
  }
  .player-infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    width: 100%;
    z-index: 1;
    .player-name,
    .player-stack-amount {
      text-align: center;
      width: 100%;
      padding: 5px;
    }
    .player-name {
      font-weight: bold;
      line-height: 10px;
      color: white;
      overflow-wrap: anywhere;
    }
    .player-stack-amount {
      font-size: 1.1em;
      font-weight: bold;
      color: #ffd700;
      line-height: 10px;
    }
  }
  .player-stats {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 2px;
    background: rgb(0 0 0);
    font-size: 1.1em;
    width: 100%;
    gap: 5px;
    .player-stats-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      &.stat--tight { color: #90caf9; }
      &.stat--loose { color: #f48fb1; }
      &.stat--passive { color: #80cbc4; }
      &.stat--aggressive { color: #ffab91; }
      &.stat--neutral { color: #b0bec5; }
      .player-stats-label {
        font-size: 0.8em;
        opacity: 0.8;
        padding-right: 2px;
      }
      .player-stats-value {
        font-weight: bold;
        font-size: 0.8em;
      }
    }
  }
}