.check-quiz {
  display: flex;
  gap: 50px;
  align-items: baseline;
  .nb-quiz {
    display: flex;
    gap: 20px;
    align-items: center;
    .value {
      font-size: 20px;
      font-weight: bold;
      border: 3px solid black;
      padding: 0 10px;
      &.green {
        background-color: #80ff80;
      }
      &.red {
        background-color: #ff9a9a;
      }
    }
  }
}