.range-viewer {
  --cell-size: 2rem;
  --cell-gap: 2px;

  &__container {
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    border-radius: 0.5rem;
    padding: 1rem;
    max-width: fit-content;
  }

  &__title {
    margin-bottom: 1rem;
    text-align: center;

    h3 {
      margin: 0;
      padding: 0;
      font-size: 1.25rem;
      font-weight: 600;
      color: #333;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(13, var(--cell-size));
    gap: var(--cell-gap);
    height: fit-content;
  }

  &__cell {
    position: relative;
    aspect-ratio: 1;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    cursor: help;  // Curseur "aide" pour indiquer qu'il y a un tooltip
    transition: all 0.2s ease;

    &:hover {
      z-index: 1;
      transform: scale(1.05);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    &.selected {
      border-color: #fff;
    }

    &__suited {
      background: #fffbdf;
    }

    &__offsuited {
      background: #ffe0e0;
    }

    &__pair {
      background: #dee9fb;
    }
  }

  &__cell-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  &__hand-text {
    position: relative;
    z-index: 2;
    font-size: 0.875rem;
    font-weight: 600;
    color: #000000;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.8);
    user-select: none;
    -webkit-user-select: none; /* Pour Safari */
    -moz-user-select: none;
  }

  &__fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
    overflow: hidden;
    border-radius: 3px;

    &-segment {
      position: absolute;
      height: 100%;
      top: 0;
      transition: all 0.2s ease;
      opacity: 0.8;
    }
  }

  // Media queries pour la responsivité
  @media (max-width: 768px) {
    --cell-size: 1.5rem;
    --cell-gap: 1px;

    &__hand-text {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 480px) {
    --cell-size: 1.2rem;

    &__hand-text {
      font-size: 0.6rem;
    }
  }
}