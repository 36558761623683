.quiz-results-ctn {
  border: 1px solid black;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  span {
    font-weight: bold;
  }
}